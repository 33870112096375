
export default [
  {
    path: "",
    name: "",
    redirect: "/fhome",
    meta: {
      title: "",
      icon: "",
      authority: "CZ_INBOUND",
      hidden: true
    }
  },
  {
    path: "/fhome",
    name: "fhome",
    fullPath: "/fhome",
    component: () => import("@/Pages/finance/home"),
    meta: {
      title: "首页",
      icon: "shouyeTb",
      authority: "1"
    },

  },


  {
    path: "/moduleDetial",
    name: "moduleDetial",
    fullPath: "/moduleDetial",
    component: () => import("@/Pages/finance/moduleDetial"),
    meta: {
      title: "模块详情",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/productDetial",
    name: "productDetial",
    fullPath: "/productDetial",
    component: () => import("@/Pages/finance/productDetial"),
    meta: {
      title: "商品详情",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/productDetial",
    name: "productDetial",
    fullPath: "/productDetial",
    component: () => import("@/Pages/finance/productDetial"),
    meta: {
      title: "商品详情",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/configurationInfo",
    name: "configurationInfo",
    fullPath: "/configurationInfo",
    component: () => import("@/Pages/finance/configurationInfo"),
    meta: {
      title: "我的配置单信息",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/configurationList",
    name: "configurationList",
    fullPath: "/configurationList",
    component: () => import("@/Pages/finance/configurationList"),
    meta: {
      title: "我的配置单列表",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/productList",
    name: "productList",
    fullPath: "/productList",
    component: () => import("@/Pages/finance/productList"),
    meta: {
      title: "产品列表",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/requirement",
    name: "requirement",
    fullPath: "/requirement",
    component: () => import("@/Pages/finance/requirement"),
    meta: {
      title: "需求表配置",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/buyCarList",
    name: "buyCarList",
    fullPath: "/buyCarList",
    component: () => import("@/Pages/finance/buyCarList"),
    meta: {
      title: "购物车详情",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/moduleDetialhotandcold",
    name: "moduleDetialhotandcold",
    fullPath: "/moduleDetialhotandcold",
    component: () => import("@/Pages/finance/moduleDetialhotandcold"),
    meta: {
      title: "冷热通道解决方案",
      icon: "",
      authority: ""
    },
  },

  {
    path: "/requirementhotandcold",
    name: "requirementhotandcold",
    fullPath: "/requirementhotandcold",
    component: () => import("@/Pages/finance/requirementhotandcold"),
    meta: {
      title: "冷热通道需求表配置",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/miniDate",
    name: "miniDate",
    fullPath: "/miniDate",
    component: () => import("@/Pages/finance/miniDate"),
    meta: {
      title: "微型数据中心",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/mdCustom",
    name: "mdCustom",
    fullPath: "/mdCustom",
    component: () => import("@/Pages/finance/miniDate/mdCustom"),
    meta: {
      title: "微型数据中心需求表配置",
      icon: "",
      authority: ""
    },
  },
  {
    path: "/personal",
    name: "personal",
    fullPath: "/personal",
    component: () => import("@/Pages/finance/personal"),
    meta: {
      title: "个人中心",
      icon: "",
      authority: ""
    },
  },
];
