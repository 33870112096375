export default [
  {
    // login
    path: "/login",
    name: "login",
    component: () => import("@/Pages/login"),
    meta: {
      noTag: true,
      title: "login",
      icon: ""
    }
  },
  {
    // 404
    path: "/404",
    name: "404",
    component: () => import("@/Pages/404"),
    meta: {
      noTag: true,
      title: "404",
      icon: ""
    }
  }
];
