import Cookies from "js-cookie";
import CryptoJS from 'crypto-js'
import fileDownload from 'js-file-download'
let keyStr = "1234567890ABCDEF"
const TokenKey = "cs_token";
export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function encrypt(word){
  keyStr = keyStr ? keyStr : '1234567890ABCDEF';
  var key  = CryptoJS.enc.Utf8.parse(keyStr);
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}
export function decrypt(word){
  var words  = CryptoJS.enc.Base64.parse(word);
  var deBase64 = CryptoJS.enc.Base64.stringify(words);
  keyStr = keyStr ? keyStr : '1234567890ABCDEF';
  var key  = CryptoJS.enc.Utf8.parse(keyStr);
  var decrypt = CryptoJS.AES.decrypt(deBase64, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
export function exportExcle(exportNmae,type,data) {
  try {
    let blob
    if(type=='pdf'){
      blob = new Blob([data], {type: 'application/pdf;charset=utf-8'})
    }else if(type=='csv'){
      blob = new Blob([data], {type: 'text/csv;charset=utf-8'})
    }
    else {
      blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
    }

    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    downloadElement.download = exportNmae
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement) // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
  } catch (error) {
    console.log(error)
    fileDownload(data, exportNmae + `.${type}`)
  }
  
}
