import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import power from "./modules/power";
import dict from "./modules/dict";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    power,
    dict
  },
  state: {
    configurationInfoList:{},
    formInline:{},
    carNum:'',
    searchName:'',
  },
  mutations: {
    updateSearchName(state, num) {
      state.searchName = num
    },
    updateCarNum(state, num) {
      state.carNum = num
    },
    updateFormInline(state, num) {
      state.formInline = num
    },
    updateConfigurationInfoList(state, num) {
      state.configurationInfoList = num
    },
  }
});

export default store;
