import Vue from "vue";
import App from "./App.vue";
require("@/Tools");
/**
 * 引入通用样式
 */
import "@/Styles/index.less"; // global css

/**
 * 引入样式的兼容
 */
import "normalize.css/normalize.css";
/**
 * 引入ElementUI
 */
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { size: "medium" });
/**
 * 引入router
 */
import router from "./permission";
/**
 * 引入store
 */
import store from "./Store";
/**
 * 引入mixins
 */
import mixins from "@/Mixins";
Vue.use(mixins);
/**
 * 引入echarts图表
 */
import echarts from 'echarts'

import Videojs from 'video.js'

import 'video.js/dist/video-js.css'

import cryptoJs from 'crypto-js'
import axios from "axios";


Vue.prototype.axiosUrl = 'https://cpq.shipgroup.net'
// Vue.prototype.axiosUrl = 'http://192.168.19.26:8080'

//Vue.prototype.axiosUrl = 'http://192.168.12.105:8888'
// if(window.location.hostname == 'cpq.shipgroup.net'){
//   Vue.prototype.axiosUrl = 'http://cpq.shipgroup.net:8888'
// } else {
//   Vue.prototype.axiosUrl = 'http://192.168.12.105:8888'
// }
//Vue.prototype.axiosUrl = 'http://192.168.4.195:8899'

//Vue.prototype.axiosUrl = 'http://192.168.12.105:8888'
Vue.prototype.$video = Videojs

Vue.prototype.$cryptoJs = cryptoJs

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {

    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

new Vue({
  render: h => h(App),
  store,
  router
}).$mount("#app");
