export function setMenu(name) {
  window.sessionStorage.setItem("menuName", name);
}

export function getMenu() {
  return window.sessionStorage.getItem("menuName");
}

export function removeMenu() {
  window.sessionStorage.removeItem("menuName");
}

export function setUserId(val) {
  window.sessionStorage.setItem("userId", val);
}

export function getUserId() {
  return window.sessionStorage.getItem("userId");
}

export function removeUserId() {
  window.sessionStorage.removeItem("userId");
}


export function setHandle(val) {
  window.sessionStorage.setItem("m_nDLLHandle", val);
}

export function getHandle() {
  return window.sessionStorage.getItem("m_nDLLHandle");
}

export function setTower(val) {
  window.sessionStorage.setItem("towerToken", val);
}

export function getTower() {
  return window.sessionStorage.getItem("towerToken");
}
