import Vue from "vue";
import VueRouter from "vue-router";
import AvueRouter from "./avue-router";
import commRouter from "./commRouter";
import { getToken } from "@/Util/auth";
Vue.use(VueRouter);

let Router = new VueRouter({

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0
      };
    }
  },
  routes: [].concat([])
});
Router.beforeEach((to, from, next) => {
  let isLogin = getToken()
  if (isLogin) {
    if (to.path === '/login') {
      next({path: '/'})
    } else {
      next();
    }
  } else {
    if (to.path === '/login') {
      next();
    } else {
      next('/login')
    }
  }
})
AvueRouter.install(Router);
Router.$avueRouter.formatRoutes();
Router.addRoutes([...commRouter]);
// 向外部跑出router
export default Router;
