<template>
  <div id="app">
    <router-view v-if="isRouterAlice"></router-view>
  </div>
</template>

<script>
  import fackClickOutSide from  './Request/fackClickOutSide'
export default {
  name: "app",
  mixins:[fackClickOutSide],
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlice: true
    };
  },
  mounted() {
    // console.log(this.$router);
    let routerArr = this.$router.$avueRouter.routerList;
    console.log(routerArr)
    this.getSilderArr(routerArr);
    this.initDeel(this.$route);
  },
  methods: {
    // 初始化框架
    initDeel(to) {
      this.routerTraversing(this.getRoutArr, to.path, "");
      if (to.meta.hidden) {
        this.setItemActive(to.meta.pName);
      } else {
        this.setItemActive(to.name);
      }
      if (!to.meta.noTag) {
        if (to.name) {
          this.setTagActive(to.name);
          this.setTagView(to);
        }
      }
    },
    reload() {
      this.isRouterAlice = false;
      this.$nextTick(function() {
        this.isRouterAlice = true;
      });
    }
  },
  //监听路由变化
  watch: {
    $route(to) {
      this.initDeel(to);
    }
  }
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
