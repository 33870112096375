import menuFinance from "./menuFinance";
import { getMenu } from "@/Util/store";
let RouterPlugin = function() {
  this.$router = null;
};
RouterPlugin.install = function(router) {
  this.$router = router;
  this.$router.$avueRouter = {
    safe: this,
    routerList: [],
    formatRoutes: function() {
      let menuArray = [];
      menuArray = [...menuFinance];

      const aRouter = [
        {
          path: "/",
          redirect: "/menuFrame"
        },
        {
          path: "/menuFrame",
          name: "menuFrame",
          component: () => import("@/ReuseComponents/menuFrame"),
          children: menuArray
        }
      ];
      this.safe.$router.addRoutes([...aRouter]);
      this.routerList = [...aRouter];
    }
  };
};
export default RouterPlugin;
